<template>
  <div>
    <div class="form">
      <table width="100%">
        <template v-for="(item,index) in questions" >
          <tr :key="item.id">
            <td ><span class="redstar" v-if="item.isRequired==1">*</span>{{index+1}}、{{item.title}}：</td>
          </tr>
          <tr :key="item.id+'one'">
            <td v-if="item.type == 1">
              <div v-for="(val,i) in (item.answer)" :key="i" class="item">
                <label><input type="radio" :name="index+'one'" :value="i" v-model="item.label" > &nbsp; {{val.title}}</label>
              </div>
            </td>
            <td v-else-if="item.type ==2">
              <div v-for="(val,i) in (item.answer)" :key="i" class="item">
                <label><input type="checkbox" :name="index+'one[]'"  v-model="val.selected"> &nbsp;   {{val.title}}</label>
              </div>
            </td>
            <td v-else-if="item.type == 3 ">
              <input type="text" class="input" v-model="item.text">
            </td>
            <td v-else-if="item.type == 4">
              <textarea style="border: 1px solid #d8eaff;background: #f5f5f6;"  cols="60" rows="10" v-model="item.text"></textarea>
            </td>
          </tr>
        </template>
        <tr>
          <td>
            <button type="button" class="btn" @click="submit" >提交</button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>

export default ({
  props:{
    id:{
      type:[Number,String],
      default:0
    }
  },
  data() {
    return {
      comment:{},
      questions:[]
    }
  },
  mounted() {
    this.getArticleData(this.id)
  },
  methods:{
    async getArticleData(articleId){
      let res = await this.$api.getQuestion({id:articleId});
      this.questions = res.data.data;
      //添加绑定数据
      this.questions.forEach(item=>{
        item.text = '';
        item.label = ''
        let temp = JSON.parse(item.answer)
        temp.forEach(val=>{
          val.selected = "";
        });
        item.answer = temp;
      })
      console.log(res.data.data,'===============')
    },
    async submit(){
      //提交数据
      //数据统计
      let answer = [];
      for (let item of this.questions){
        let obj = {}
        //单选题
        obj.qid = item.id;
        obj.title = item.title
        if(item.type ==1){
          if(item.label === ''){
            return this.$message.error(`请先选择${item.title}的答案`);
          }
          obj.answer = item.answer[item.label].title
        }else if (item.type == 2){
          let str = [];
          for (let val of item.answer){
            //全为空
            if(val.selected){
              str.push(val.title)
            }
          }
          obj.answer = str.join(',')
        }else{
          if(item.isRequired == 1 && item.text === ''){
            return this.$message.error(`请先填写${item.title}的内容`);
          }
          obj.answer = item.text
        }
        answer.push(obj)
      }
      //获得答案
      console.log('获取答案为',answer);
      let res = await this.$api.submitQuestion(answer);
      if(res.data.code == 200){
        this.$message({
          message: '提交成功',
          type: 'success'
        });
        this.getArticleData(this.articleId)
      }else{
        this.$message.error(res.data.msg);
      }
    }
  }
})
</script>

<style scoped>
.item{
  margin: 10px !important;
}
</style>
