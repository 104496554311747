<template>
  <div>
    <div class="newsitem-title">
      {{ articleDetail.title }}
    </div>
    <div class="source">
      <div class="t1">
        来源:
      </div>
      <div class="t2">
        {{ articleDetail.source }}
      </div>
      <div class="t3">
        时间:
      </div>
      <div class="t4">
        {{ articleDetail.publishTime }}
      </div>
    </div>
    <div v-html="articleDetail.content || ''">

    </div>

    <template v-if="isPlan">
      <button type="button" class="btn" @click="navTo()" v-if="boolTime(articleDetail.endTime)">我要申请</button>
      <button type="button" class="btn" style="background-color:#a1a1a1" v-else>已结束</button>
    </template>
    <div v-if="articleDetail && articleDetail.fileUrlList.length > 0">
      <ul v-if="$store.state.stations.id == 1005010">
        <li v-for="(item,index) in articleDetail.fileUrlList" :key="index+'one'" style="margin-bottom: 10px">
          <span style="cursor: pointer" @click="downLoad(item.url,item.name)">
            <img src="/images/fujian.png" alt="" style="width: 20px">
            {{ item.name }}
          </span>
        </li>
      </ul>
      <ul v-else>
        <li v-for="(item,index) in articleDetail.fileUrlList" :key="index+'one'" style="margin-bottom: 10px">
          <a :href="item.url" style="color: #2d52a0">
            <img src="/images/fujian.png" alt="" style="width: 20px">
            {{ item.name }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default ({
  props: {
    articleId: {
      type: [Number, String],
      default: 0
    },
    isPlan: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      articleDetail: ''
    }
  },
  mounted() {
    //获取详情
  },
  methods: {
    async getArticleData(articleId) {
      let res = await this.$api.getArticleData({id: articleId});
      this.articleDetail = res.data.data;
      console.log(res.data)
    },
    navTo() {
      this.$router.push('/channels/374/393.html?planId=' + this.articleId)
    },
    boolTime(date) {
      let listDate = new Date(date);
      let now = new Date();
      return now - listDate
    },
    downLoad(downUrl, fileName) {
      this.$confirm('确定要下载该文件吗？', '确认信息', {
        confirmButtonText: '下载',
        cancelButtonText: '取消'
      })
          .then(() => {
            //下载文件
            axios({
              method: 'post',
              url:  this.$baseURL + "/api/fileDownload",
              //重要：相应类型必须为blob
              responseType: 'blob',
              data:{
                fileName:downUrl
              },
              headers:{}
            }).then(res=>{
              console.log(res)
              //获取文件名
              let newFileName = fileName;
              let blob = new Blob([res.data]);
              let url = window.URL.createObjectURL(blob); // 创建 url 并指向 blob
              let a = document.createElement('a');
              a.href = url;
              a.download = newFileName;
              a.click();
              window.URL.revokeObjectURL(url);
            })
          })
          .catch(action => {

          })
    }
  },
  watch: {
    articleId(newv) {
      this.getArticleData(newv)
    }
  }
})
</script>

<style scoped>
.btn {
  font-size: 16px;
  width: 113px;
  height: 34px;
  border-radius: 34px;
  display: block;
  background: #244991;
  color: #fff;
  margin: 20px auto;
}
</style>
