<template>
  <div>
    <div class="banner-wrapper">
      <div class="banner_info" :style="`background: url(${bannerUrl}) center no-repeat;background-size: cover;`" v-if="leftData.length>0 && leftData[0]">
        <dl>
          <dt>{{leftData[0].name}}</dt>
          <dd>{{leftData[0].name_en}}</dd>
        </dl>
      </div>
    </div>
    <div class="infopage">
<!--      <div class="container" v-if="$store.state.stations.name.includes('中联')">-->
<!--        &lt;!&ndash;中联&ndash;&gt;-->
<!--        <zl_certification  />-->
<!--      </div>-->
      <div class="container" v-if="!$store.state.stations.name.includes('中联')">
        <certification @queryClick="queryClick" />
      </div>
      <!--&lt;!&ndash; 是顶级栏目而且 图片栏目模型 &ndash;&gt;-->
      <!--<div class="container clearfix"  v-if="columnId == pid && leftData[0].template == 'picture_column'">-->
      <!--  展示图片栏目模型-->
      <!--</div>-->
      <!--&lt;!&ndash; 是顶级栏目而且 图片新闻模型 &ndash;&gt;-->
      <!--<div class="container clearfix" v-else-if="columnId == pid && leftData[0].template == 'list_type_1'">-->
      <!--  展示普通列表数据-->
      <!--</div>-->
      <!--其他风格模型-->
      <div class="container clearfix" >
        <!--左侧导航分类展示-->
        <template v-if="leftData.length > 0 && leftData[0].children.length>0 && leftData[0].children[0].children.length>0">
          <div class="left" v-if="(leftData.length > 0 && leftData[0].template != 'picture_column') || columnPid !=0 ">
            <div class="box" v-for="(item,index) in leftData[0].children" :key="item.id" >
              <dl v-if="index==0" >
                <dt>{{leftData[0].name}}</dt>
                <dd>{{leftData[0].name_en}}</dd>
              </dl>
              <!--一级栏目是否展开 on展开 active高亮-->
              <!--<h3 :class="{on:item.id == id,active:cId == item.id}" @click="tabClick(item,index)">-->

              <!--如果有三级栏目，则展示三级子栏目内容-->
              <template v-if="item.children.length > 0 ">
                <h3 :class="{on:(item.id == columnId || item.id ==  columnPid),mt:index==0}">
                  <a href="javascript:;" @click="navTo(item)">{{item.name}}</a>
                  <span><i></i></span>
                </h3>
                <ul>
                  <!--默认只有选中的栏目才展开，其他折叠-->
                  <template v-if="item.id == columnId || item.parent_id == pid || item.id ==  columnPid || (columnId == pid && index == 0)">
                    <!--如果栏目id相同则自动选中，或则不相同选中第一个-->
                    <li @click="navTo(val)" v-for="(val,i) in item.children" :key="val.id"
                        :class="{on:(columnId==val.id) || (columnId==item.id && i==0) || (columnId == pid && index == 0 && i==0)}" >
                      <a href="javascript:;">{{val.name}}</a>
                    </li>
                  </template>
                </ul>
              </template>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="left" >
            <div class="box" v-if="(leftData.length > 0 && leftData[0].template != 'picture_column' && leftData[0].children.length > 0) || columnPid !=0 ">
              <dl>
                <dt class="pt">{{leftData[0].name}}</dt>
              </dl>
              <ul>
                <li @click="navTo(val)" :class="{on:val.id == columnId || (columnId==pid && i ==0)}"
                    v-for="(val,i) in leftData[0].children" :key="val.id">
                  <a href="javascript:;">{{val.name}}</a>
                </li>
              </ul>
            </div>

          </div>
        </template>
        <div class="right" :class="{right_lg:pid == columnId && leftData.length > 0 &&  leftData[0].children.length==0}">
          <div class="box">
            <div class="title flex lg" v-if="currentItem">
              <div class="flex_item"><b class="">{{currentItem.name}}</b></div>
              <div class="bread">
                <a href="#">首页</a> > <a href="#" v-if="leftData.length>0">{{leftData[0].name}}</a> > <span>{{currentItem.name}}</span>
              </div>
            </div>
            <div class="newsitem" v-if="isSurvey">
              <questions :id="articleId"></questions>
            </div>
            <div class="newsitem" v-else>
              <contents :isPlan="isPlan" :articleId="articleId"></contents>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import certification from "@/components/certification";
import zl_certification from "@/components/zlCertification";
import contents from "@/components/article";
import questions from "@/components/questions";

import {mapMutations, mapState} from "vuex";

export default {

  components:{
    certification,
    contents,
    questions,
    zl_certification
  },
  data() {
    return {
      columnId:0,
      pid:0,
      leftData:[],
      columnPid:0,
      currentItem:{},
      articleId:0,
      isSurvey:false,
      showAnnounce:false,
      queryParam:{},
      isPlan:false
    }
  },
  mounted() {
    $("#left").hide();
    $("#right").hide();
    this.setShowTips(false)
    this.articleId = this.$route.params.articleId.slice(0,-5);
    if(this.articleId.includes("survey")){
      //调查列表
      this.isSurvey = true;
      this.articleId = this.articleId.split("_")[1] || 0
    }
    this.columnId = this.$route.params.id || 0
    this.pid = this.$route.params.pid || 0;
    this.isPlan  = this.$route.query.apply || false
    console.log(this.pid);
    //设置索引值
    let index = this.columnData.findIndex(item=>item.id == this.pid)
    if(index != -1){
      this.setTabIndex(index + 1);
    }
    if(this.pid != 0 ){
      this.getAllColumn();
    }
  },
  methods:{
    ...mapMutations(['setShowTips','setTabIndex']),
    async getAllColumn(){
      let res = await this.$api.getAllColumn({
        pid:this.pid,
        id:this.columnId
      });
      this.leftData = res.data.data.list;
      this.columnPid = res.data.data.pid.parentId
      console.log("栏目父id",this.columnPid);
      //通过栏目确定当前选中的栏目
      if(this.leftData[0]?.children.length > 0){
        if(this.columnId == this.pid){
          if(this.leftData[0].template == 'picture_column'){
            //如果是图片栏目
            this.currentItem = this.leftData[0];
          }else if(this.leftData[0]?.children[0]?.children.length > 0){
            this.currentItem = this.leftData[0].children[0].children[0]
            console.log(0)
          }else{
            this.currentItem = this.leftData[0]?.children[0];
            console.log(1)
          }
        }else if(this.columnPid == this.pid){
          let index = this.leftData[0]?.children.findIndex(item=>item.id == this.columnId);
          this.currentItem = this.leftData[0]?.children[index]?.children[0]
          if(this.leftData[0]?.children[index]?.children.length > 0){
            this.currentItem = this.leftData[0]?.children[index]?.children[0]
          }else{
            this.currentItem = this.leftData[0]?.children[index]
          }
        }else {
          console.log(3)
          this.currentItem = res.data.data.pid
        }
      }else{
        this.currentItem = this.leftData[0];
      }
      console.log(this.currentItem)
      console.log('当前选中的栏目为'+this.currentItem?.id)
      console.log('当前选中的栏目为'+this.currentItem?.name)
    },
    navTo(item){
      this.$router.push(`/channels/${this.pid}/${item.id}.html`)
    },
    toDetail(id){
      this.$router.push(`/contents/${this.pid}/${this.columnId}/${id}.html`)
    },
    queryClick(e){
      this.showAnnounce = true;
      //查询通知公告内容
      this.queryParam = e
      this.$nextTick(function (){
        this.$refs.cert.getList()
      })
    }
  },
  computed:{
    ...mapState(['columnData']),
    bannerUrl(){
      if(this.leftData.length > 0 && this.leftData[0]?.imgUrl){
        return this.$baseURL + this.leftData[0]?.imgUrl
      }
      return '/images/1_02.jpg'
    }
  }
}
</script>

<style scoped>
.mb{
  margin-bottom: 20px;
}
.mt{
  margin-top: 20px;
}
</style>
